import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

import DescriptionWithImage from 'components/DescriptionWithImage';

import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import * as styles from 'styles/shared.module.scss';

export default function AboutUs() {
  const query = graphql`
  {
    allStrapiAboutUsRows {
      nodes {
        id
        firstParagraph
        secondParagraph
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    allStrapiAboutUsCitations {
      nodes {
        id
        citation
        author
      }
    }
  }
`;

  const data = useStaticQuery(query);
  // const image = getImage(data.allStrapiLandingPage.nodes[0].background.localFile);
  const rowsData = data.allStrapiAboutUsRows.nodes;
  const citationData = data.allStrapiAboutUsCitations.nodes[0];

  return (
    <Flex justify="center" align="center" direction="column">

      {rowsData.map((row, index) => {
        const gatsbyImg = getImage(row.image.localFile);
        return (
          <Box className={
            index % 2 === 0
              ? styles.grayPresentationContainer
              : styles.whitePresentationContainer
          }
          >
            <Box className={styles.descriptionWithImageWrapper}>
              <DescriptionWithImage
                title={row.title}
                firstParagraph={row.firstParagraph}
                secondParagraph={row.secondParagraph}
                image={gatsbyImg}
                right={index % 2 === 0}
              />
            </Box>
          </Box>
        );
      })}

      <Box className={styles.grayPresentationContainer}>
        <Flex justify="center" align="center" direction="column" className={styles.quoteContainer}>
          <Flex justify="center" align="center" direction="row">
            <Text as="cite" className={styles.quote}>
              <FontAwesomeIcon
                icon={faQuoteLeft}
                style={{
                  fontSize: 10, marginRight: 10, marginBottom: 20, color: '#E57B74',
                }}
              />
              {`${citationData.citation}.`}
              <FontAwesomeIcon
                icon={faQuoteRight}
                style={{
                  fontSize: 10, marginLeft: 10, marginBottom: 20, color: '#E57B74',
                }}
              />
            </Text>
          </Flex>
          <Text fontSize="xl" className={styles.quoteAuthor}>{citationData.author}</Text>
        </Flex>
      </Box>

    </Flex>
  );
}
